import { format } from 'date-fns'
import { DATE_FORMAT } from '../../utils/date-formatting.js'
import { configApi } from '../http-client.js'

export const getChannelDependencies = () => configApi.get('/api/ChannelDependency').then(({ data }) => ({
	channelDependencies: data.map(channelDependency => ({
		...channelDependency,
		createdDate: format(new Date(channelDependency.createdDate), DATE_FORMAT)
	}))
}))
// export const getChannelDependencies = () => configApi.get('/api/ChannelDependency')

export const postChannelDependency = channelDependency => configApi.post('/api/ChannelDependency', channelDependency)

export const putChannelDependency = channelDependency => configApi.put('/api/ChannelDependency', channelDependency)

export const deleteChannelDependency = channelDependencyId => configApi.delete(`/api/ChannelDependency/${channelDependencyId}`)
