<template>
	<ValidationForm
		v-if="!showConfirmModal"
		#default="{ handleSubmit }"
	>
		<Modal
			v-if="!showConfirmModal"
			width="650px"
		>
			<template #modal-title>
				{{ modalTitle }} Channel Dependency
				<v-spacer />
				<IconButton
					@click="$emit('close')"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="12">
							<Dropdown
								v-model="channelDependency.originalChannelId"
								label="Original Channel"
								:items="originalChannelItems"
								rules="required"
								tooltip-text="Select the Channel on which our dependent action will be triggered"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Dropdown
								v-model="channelDependency.originalValue"
								label="Original Channel Preference Value"
								:items="channelValueItems"
								rules="required"
								tooltip-text="Select the preference value which will trigger the action"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Dropdown
								v-model="channelDependency.dependentChannelId"
								label="Dependent Channel"
								:items="dependentChannelItems"
								rules="required"
								tooltip-text="Select the Channel which will be updated if the Original Channel action is met"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Dropdown
								v-model="channelDependency.dependentValue"
								label="Dependent Channel Preference Value"
								:items="channelValueItems"
								rules="required"
								tooltip-text="Set the preference value which will be triggered if the Original Channel action is met"
							/>
						</v-col>
					</v-row>
					<v-row v-if="showPreviewString">
						<v-col cols="12">
							{{ previewString }}
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton
					@click="$emit('close')"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					@click="handleSubmit(showConfirm)"
				>
					{{ isEdit ? 'Save' : 'Create' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
	<Modal
		v-else
		width="650px"
	>
		<template #modal-title>
			{{ isEdit ? 'Update' : 'Create' }} Channel Dependency
		</template>
		<template #modal-content>
			<p>
				Are you sure you wish to {{ isEdit ? 'edit' : 'create' }} this Channel Dependency?
			</p>
			<p>
				Any changes in this module can have a significant impact on the system behavior.
			</p>
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="showConfirmModal = false">
				Cancel
			</SecondaryActionButton>
			<PrimaryActionButton @click="submit">
				Save Changes
			</PrimaryActionButton>
		</template>
	</Modal>
</template>

<script>
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import Modal from '../../../../../../shared/components/modal.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import { postChannelDependency, putChannelDependency } from '../../../../../../shared/utils/api/channel-dependencies.js'

export default {
	components: {
		ValidationForm,
		Modal,
		IconButton,
		Dropdown,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		channelDependencyToEdit: Object,
		channels: Array
	},
	data () {
		return {
			channelDependency: JSON.parse(JSON.stringify(this.channelDependencyToEdit || {
				dependentChannelId: null,
				dependentValue: null,
				originalChannelId: null,
				originalValue: null
			})),
			showConfirmModal: false
		}
	},
	computed: {
		isEdit () {
			return !!this.channelDependencyToEdit
		},
		modalTitle () {
			return this.isEdit ? 'Edit' : 'Create'
		},
		channelValueItems () {
			return [
				{
					text: 'Opt In',
					value: 'IN'
				},
				{
					text: 'Opt Out',
					value: 'OUT'
				}
			]
		},
		originalChannelItems () {
			return this.channels.filter(({ value }) => value !== this.channelDependency.dependentChannelId)
		},
		dependentChannelItems () {
			return this.channels.filter(({ value }) => value !== this.channelDependency.originalChannelId)
		},
		showPreviewString () {
			return this.channelDependency.originalChannelId &&
				this.channelDependency.originalValue &&
				this.channelDependency.dependentChannelId &&
				this.channelDependency.dependentValue
		},
		originalChannelName () {
			return this.originalChannelItems.find(({ value }) => value === this.channelDependency.originalChannelId)?.text
		},
		dependentChannelName () {
			return this.dependentChannelItems.find(({ value }) => value === this.channelDependency.dependentChannelId)?.text
		},
		previewString () {
			const originalChannelOptIn = this.channelDependency.originalValue === 'IN'
			const dependentChannelOptIn = this.channelDependency.dependentValue === 'IN'
			return `Opting ${originalChannelOptIn ? 'IN to' : 'OUT of'} ${this.originalChannelName}
				will see the Data Subject Opted ${dependentChannelOptIn ? 'IN to' : 'OUT of'} ${this.dependentChannelName}`
		}
	},
	methods: {
		async submit () {
			this.channelDependency.parentFlag = this.originalChannelItems.find(({ value }) => value === this.channelDependency.originalChannelId)?.isParentChannel
			this.channelDependency.alignmentType = 2
			if (!this.isEdit) {
				await postChannelDependency(this.channelDependency)
			} else {
				await putChannelDependency(this.channelDependency)
			}
			this.$emit('submit')
		},
		showConfirm () {
			this.showConfirmModal = true
		}
	}
}
</script>
