<template>
	<ViewLayout>
		<template #header-title>
			{{ 'Channel Dependencies' | useLabels }}
		</template>
		<template #content>
			<div class="cassie-vertical-md">
				<FilterBar
					:search-query.sync="originalChannelNameSearchQuery"
					search-query-label="Original Channel"
					hide-brand-filter
					@clearFilters="clearFilters"
				>
					<template #after-filters>
						<TextField
							v-model="dependentChannelNameSearchQuery"
							label="Dependent Channel"
						/>
					</template>
					<template #action>
						<PrimaryActionButton
							v-if="userFullPermissions"
							@click="onCreateClick"
						>
							<v-icon
								left
								dark
							>
								mdi-plus
							</v-icon>
							Create
						</PrimaryActionButton>
					</template>
				</FilterBar>
				<SectionCard>
					<template #title>
						{{ 'Channel Dependencies' | useLabels }}
					</template>
					<template #body>
						<DataTable
							:items="filteredChannelDependencies"
							:headers="tableHeaders"
							sort-by="id"
							sort-desc
							@click:row="onEditClick"
						>
							<template #item.originalChannelName="{ item }">
								{{ getTruncatedText(item.originalChannelName, 50) }}
							</template>
							<template #item.originalValue="{ item }">
								{{ item.originalValue === 'IN' ? 'Opt In' : 'Opt Out' }}
							</template>
							<template #item.dependentChannelName="{ item }">
								{{ getTruncatedText(item.dependentChannelName, 50) }}
							</template>
							<template #item.dependentValue="{ item }">
								{{ item.dependentValue === 'IN' ? 'Opt In' : 'Opt Out' }}
							</template>
							<template #item.action="{ item }">
								<IconButton
									v-if="userFullPermissions"
									@click="onEditClick(item)"
								>
									mdi-pencil
								</IconButton>
								<IconButton
									v-if="userFullPermissions"
									@click.stop.prevent="channelDependencyToDelete = item"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</template>
				</SectionCard>
			</div>
			<ChannelDependencyManageModal
				v-if="showManageModal && userFullPermissions"
				:channels="channelItems"
				:channel-dependency-to-edit="channelDependencyToEdit"
				@close="closeModal"
				@submit="submit"
			/>
			<ConfirmDeleteModal
				v-if="channelDependencyToDelete"
				:entity-name="`Channel Dependency - ID ${channelDependencyToDelete.id}`"
				entity-type="Channel Dependency"
				@close="channelDependencyToDelete = null"
				@delete="deleteChannelDependency"
			/>
		</template>
	</ViewLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import ChannelDependencyManageModal from './channel-dependency-manage-modal.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import { getPreferenceChannels } from '../../../../../../shared/utils/api/channels.js'
import { getChannelDependencies, deleteChannelDependency } from '../../../../../../shared/utils/api/channel-dependencies.js'
import { CHANNEL_DEPENDENCIES_FULL_PERMISSIONS, BUSINESS_RULES_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { getTruncatedText } from '../../../../../../shared/utils/utils.js'
export default {
	components: {
		ViewLayout,
		FilterBar,
		TextField,
		PrimaryActionButton,
		SectionCard,
		DataTable,
		ChannelDependencyManageModal,
		ConfirmDeleteModal,
		IconButton
	},
	data () {
		return {
			originalChannelNameSearchQuery: null,
			dependentChannelNameSearchQuery: null,
			showManageModal: false,
			channels: [],
			channelDependencies: [],
			channelDependencyToEdit: null,
			channelDependencyToDelete: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			return this.productAreaPermission(CHANNEL_DEPENDENCIES_FULL_PERMISSIONS) || this.productAreaPermission(BUSINESS_RULES_MODULE_FULL_PERMISSIONS)
		},
		tableHeaders () {
			const headers = [
				{
					text: 'ID',
					value: 'id'
				},
				{
					text: 'Original Channel',
					value: 'originalChannelName'
				},
				{
					text: 'Original Channel Preference Value',
					value: 'originalValue'
				},
				{
					text: 'Dependent Channel',
					value: 'dependentChannelName'
				},
				{
					text: 'Dependent Channel Preference Value',
					value: 'dependentValue'
				},
				{
					text: 'Created Date',
					value: 'createdDate'
				},
				{
					text: 'Action',
					value: 'action',
					sortable: false
				}
			]

			if (!this.userFullPermissions) {
				return headers.filter(({ value }) => value !== 'action')
			} else return headers
		},
		filteredChannelDependencies () {
			const channelDependencies = this.channelDependencies?.map(channelDependency => ({
				...channelDependency,
				originalChannelName: this.channels.find(({ channelId }) => channelId === channelDependency.originalChannelId)?.channelName,
				dependentChannelName: this.channels.find(({ channelId }) => channelId === channelDependency.dependentChannelId)?.channelName
			}))
			return channelDependencies.filter(({ originalChannelName, dependentChannelName }) => {
				let check = true
				if (this.originalChannelNameSearchQuery) check = originalChannelName.toLowerCase().includes(this.originalChannelNameSearchQuery.toLowerCase())
				if (this.dependentChannelNameSearchQuery) check = dependentChannelName.toLowerCase().includes(this.dependentChannelNameSearchQuery.toLowerCase())
				return check
			})
		},
		channelItems () {
			return this.channels.map(({ channelId, channelName, channelType }) => ({
				text: getTruncatedText(channelName, 50),
				value: channelId,
				isParentChannel: channelType.typeID === 1
			}))
		}
	},
	async created () {
		await this.loadChannels()
		await this.loadChannelDependencies()
	},
	methods: {
		async loadChannels () {
			const { data: { channels } } = await getPreferenceChannels()
			this.channels = channels
		},
		async loadChannelDependencies () {
			const { channelDependencies } = await getChannelDependencies()
			this.channelDependencies = channelDependencies
		},
		onCreateClick () {
			this.showManageModal = true
		},
		onEditClick (channelDependency) {
			this.channelDependencyToEdit = channelDependency
			this.showManageModal = true
		},
		closeModal () {
			this.showManageModal = false
			this.channelDependencyToEdit = null
		},
		async submit () {
			this.showManageModal = false
			this.channelDependencyToEdit = null
			await this.loadChannelDependencies()
		},
		clearFilters () {
			this.dependentChannelNameSearchQuery = null
		},
		async deleteChannelDependency () {
			await deleteChannelDependency(this.channelDependencyToDelete.id)
			this.channelDependencyToDelete = null
			await this.loadChannelDependencies()
		},
		getTruncatedText (str, n) {
			return (str.length > n) ? str.slice(0, n - 1) + '...' : str
		}
	}
}
</script>
