var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ViewLayout',{scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("useLabels")('Channel Dependencies'))+" ")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"cassie-vertical-md"},[_c('FilterBar',{attrs:{"search-query":_vm.originalChannelNameSearchQuery,"search-query-label":"Original Channel","hide-brand-filter":""},on:{"update:searchQuery":function($event){_vm.originalChannelNameSearchQuery=$event},"update:search-query":function($event){_vm.originalChannelNameSearchQuery=$event},"clearFilters":_vm.clearFilters},scopedSlots:_vm._u([{key:"after-filters",fn:function(){return [_c('TextField',{attrs:{"label":"Dependent Channel"},model:{value:(_vm.dependentChannelNameSearchQuery),callback:function ($$v) {_vm.dependentChannelNameSearchQuery=$$v},expression:"dependentChannelNameSearchQuery"}})]},proxy:true},{key:"action",fn:function(){return [(_vm.userFullPermissions)?_c('PrimaryActionButton',{on:{"click":_vm.onCreateClick}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create ")],1):_vm._e()]},proxy:true}])}),_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("useLabels")('Channel Dependencies'))+" ")]},proxy:true},{key:"body",fn:function(){return [_c('DataTable',{attrs:{"items":_vm.filteredChannelDependencies,"headers":_vm.tableHeaders,"sort-by":"id","sort-desc":""},on:{"click:row":_vm.onEditClick},scopedSlots:_vm._u([{key:"item.originalChannelName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTruncatedText(item.originalChannelName, 50))+" ")]}},{key:"item.originalValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.originalValue === 'IN' ? 'Opt In' : 'Opt Out')+" ")]}},{key:"item.dependentChannelName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTruncatedText(item.dependentChannelName, 50))+" ")]}},{key:"item.dependentValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dependentValue === 'IN' ? 'Opt In' : 'Opt Out')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.userFullPermissions)?_c('IconButton',{on:{"click":function($event){return _vm.onEditClick(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.userFullPermissions)?_c('IconButton',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.channelDependencyToDelete = item}}},[_vm._v(" mdi-trash-can ")]):_vm._e()]}}])})]},proxy:true}])})],1),(_vm.showManageModal && _vm.userFullPermissions)?_c('ChannelDependencyManageModal',{attrs:{"channels":_vm.channelItems,"channel-dependency-to-edit":_vm.channelDependencyToEdit},on:{"close":_vm.closeModal,"submit":_vm.submit}}):_vm._e(),(_vm.channelDependencyToDelete)?_c('ConfirmDeleteModal',{attrs:{"entity-name":("Channel Dependency - ID " + (_vm.channelDependencyToDelete.id)),"entity-type":"Channel Dependency"},on:{"close":function($event){_vm.channelDependencyToDelete = null},"delete":_vm.deleteChannelDependency}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }